import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import axios from 'axios';
import { allDeviceAtom } from '../atoms/allDeviceAtom';
import DeviceTable from '../charts-graphs/deviceTable';
import ConvertTime from '../converters/timestampConverter';
import Device from '../defs/device-def';

export default function DeviceTableHandler() {
  const [allDevice, setAllDevice] = useAtom(allDeviceAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['allCars'],
    staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
    refetchInterval: 1000 * 60 * 1, // Refetch data every 2 minutes
    queryFn: async () => {
      const response = await axios.get<Device[]>('https://smartgolf.ai/api/all-devices');
      const data = response.data;
      UpdateAllDevice(data);
      console.log("all cars: ", allDevice);
      return data;
    }
  });

  function UpdateAllDevice(data: Device[]) {
    const updatedAllDevice = data?.map(dev => ({
      ...dev,
      lastRx: ConvertTime(dev.lastRx)
    }));
    setAllDevice(updatedAllDevice);
  }

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <>
      {renderLoadingOrError()}
      <DeviceTable />
    </>
  );
};


import { useSetAtom, useAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { devicePathAtom } from '../atoms/devicePathAtom';
import ConvertTime from '../converters/timestampConverter';
import DevLocation from '../defs/devLocation-def';

export const useFetchDevicePath = (deviceID: string | null) => {
  const setDevicePath = useSetAtom(devicePathAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['devicePath', deviceID],
    staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
    queryFn: async () => {
      if (!deviceID) return []; // Return an empty array if deviceID is null

      try {
        const response = await axios.get<DevLocation[]>(`https://smartgolf.ai/api/device-path/${deviceID}`);
        const data = response.data;
        UpdateDevicePath(data);
        return data;
      } catch (error) {
        console.error('Error fetching device path:', error);
        throw error;
      }
    },
    enabled: !!deviceID
  });

  function UpdateDevicePath(data: DevLocation[]) {
    const updatedDevicePath = data?.map(loc => ({
      ...loc,
      timestamp: ConvertTime(loc.timestamp)
    }));
    setDevicePath(updatedDevicePath);
  }

  return { isFetching, isError, error };
};







